export const channels = [
  {
    label: "Atlanta Youtube",
    value: {
      id: "UCW0DrliWtQy4jOdtKdgrhdQ",
      type: "youtube",
      url:
        "https://www.youtube.com/embed/live_stream?channel=UCW0DrliWtQy4jOdtKdgrhdQ"
    }
  },
  {
    label: "Atlanta Twitch",
    value: {
      id: "49730541",
      type: "twitch",
      url: "imaqtpie"
    }
  },
  {
    label: "Santiago",
    value: {
      id: "UCJd7y4iYnX432GF7YnKZrzQ",
      type: "youtube",
      url:
        "https://www.youtube.com/embed/live_stream?channel=UCJd7y4iYnX432GF7YnKZrzQ"
    }
  }
];

export const getChannel = region => {
  let temp = channels[0];
  channels.map(channel => {
    if (channel.value === region) temp = channel;
  });
  return temp;
};
