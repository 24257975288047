import React from "react";
import cookie from "js-cookie";
import axios from "axios";
import windowSize from "react-window-size";
import {
  InfoBox,
  Input,
  Image,
  Row,
  Column,
  Button,
  Text
} from "../../globalComponents";
import Logo from "../../assets/logos/Main_Logo.png";
import Page_Background from "../../assets/Page_Background.jpg";

class Login extends React.Component {
  state = {
    email: "",
    password: "",
    error: { name: "", text: "" }
  };
  login = () => {
    axios
      .post("/user/login", {
        email: this.state.email,
        password: this.state.password
      })
      .then(res => {
        if (res.data.success) {
          this.setState({ error: { name: "", text: "" } });
          cookie.set("token", res.data.token);
          cookie.set(
            "name",
            res.data.user.firstName + " " + res.data.user.lastName
          );
          window.location.reload();
        } else
          this.setState({
            error: { name: "general", text: res.data.error }
          });
      })
      .catch(err => {
        console.error(err);
        this.setState({
          error: { name: "general", text: "Server Error Try Again" }
        });
      });
  };
  render() {
    const { email, password, error } = this.state;
    return (
      <Column>
        <img
          src={Page_Background}
          style={{
            position: "fixed",
            height: "100vh",
            minWidth: "100vw",
            zIndex: -1
          }}
        />
        <InfoBox padding="0" margin="10vh auto auto auto" width={"30vw"}>
          <Row backgroundColor="rgba(0,0,0,.1)" width="100%">
            <Image src={Logo} height="20vh" />
          </Row>
          <Row margin="2%">
            <Input label="Email" onChange={v => this.setState({ email: v })} />
            <Input
              label="Password"
              type="password"
              onChange={v => this.setState({ password: v })}
            />
            <Text color="red">{error.name == "general" && error.text}</Text>
            <Button margin="3% auto auto auto" onClick={this.login}>
              Login
            </Button>
          </Row>
          <Row backgroundColor="rgba(0,0,0,.1)" width="100%">
            <Text size="1.5vh" margin="2% auto 2% auto">
              Trouble Signing in? <a href="/reset">Reset Password</a>
            </Text>
          </Row>
        </InfoBox>
      </Column>
    );
  }
}
export default windowSize(Login);
