import React, { Component } from "react";
import windowSize from "react-window-size";
import styled from "styled-components";
import { Column, Text, Button } from "./index.js";
import Theme from "./Theme.js";

const Popup = styled.div`
  background-color: white;
  margin: auto;
  border-radius: 2%;
  padding: 2%;
  z-index: 99;
`;
class Notification extends Component {
  render() {
    const { message, title, error, open, onClose } = this.props;
    const mobile = this.props.windowWidth < 700;
    return (
      <div
        style={{
          backgroundColor: Theme.BLACK,
          position: "absolute",
          width: "100vw",
          height: "100vh",
          display: open ? "flex" : "none"
        }}
      >
        <Popup open={open}>
          <Column width={mobile ? "80vw" : "40vw"}>
            <Text
              margin={"0 auto 0 auto"}
              size={mobile ? "5vw" : "3vw"}
              color={error ? "red" : "black"}
            >
              {title}
            </Text>
            <div
              style={{
                width: "80%",
                margin: "0 auto 0 auto ",
                border: `2px solid ${Theme.BLACK}`
              }}
            />
            <Text
              margin="2vw auto auto auto"
              size={mobile ? "3vw" : "1.5vw"}
              color="black"
            >
              {message}
            </Text>

            <Button
              margin="4vh auto 0 auto"
              background={Theme.BLUE}
              onClick={onClose}
              width={mobile ? "30vw" : "20vw"}
              fontSize={mobile ? "3vw" : "1vw"}
            >
              Confirm
            </Button>
          </Column>
        </Popup>
      </div>
    );
  }
}
export default windowSize(Notification);
