const theme = {
  PAGE_BACKGROUND: "#4C4C4C",
  MAIN_COLOR: "rgba(60, 59, 110,1)",
  SHADOW_COLOR: "rgba(0,0,0,1)",
  INPUT_OUTLINE_COLOR: "rgba(0,0,0,.3)",
  INPUT_COLOR: "rgba(255,255,255,1)",
  BORDER_COLOR: "#727272",
  NAV_COLOR: "rgba(255,255,255,1)",
  DARK_GREY: "#535354",
  LIGHT_GREY: "#79797A",
  BLUE: "rgba(60, 59, 110,1)", //"#586387",
  BLACK: "rgba(24,24,25,.85)",
  WHITE: "rgba(255,255,255,1)",
  RED: "rgba(178, 34, 52,1)",
  GREEN: "#6B8E23",
  BANNER_FONT: "sans-serif",
  MAIN_FONT: "sans-serif"
};

export default theme;
