import React, { Component } from "react";
import styled, { keyframes, css } from "styled-components";
import Theme from "./Theme.js";

const Wrapper = styled.table`
  margin: auto;
  border: 0.1vw solid;
  border-color: ${props => props.borderColor || Theme.BLACK};
  border-collapse: collapse;
`;
const Header = styled.th`
  width: 10vw;
  border: 0.1vw solid;
  border-color: ${props => props.borderColor || Theme.BLACK};
  background-color: ${props => props.backgroundColor || Theme.BLACK};
  color: ${props => props.color || "white"};
`;

const Column = styled.td`
  width: 10vw;
  border: 0.1vw solid;
  border-color: ${props => props.borderColor || Theme.BLACK};
  background-color: ${props => props.backgroundColor || Theme.WHITE};
  color: ${props => props.color || "white"};
`;

const Row = styled.tr``;
export default class Table extends Component {
  render() {
    const { columns, data } = this.props;
    return (
      <Wrapper>
        <tbody>
          <Row>
            {columns.map((column, index) => (
              <Header key={index + "header"}>{column.label}</Header>
            ))}
          </Row>
          {data.map((row, index) => (
            <Row>
              {columns.map((column, index) => (
                <Column margin="1%" key={index + "header"}>
                  {column.render(row)}
                </Column>
              ))}
            </Row>
          ))}
        </tbody>
      </Wrapper>
    );
  }
}
