import React, { Component, Window } from "react";
import styled, { keyframes, css } from "styled-components";
import Circular from "../assets/logos/Secondary_Logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faBars } from "@fortawesome/free-solid-svg-icons";

import {
  Dropdown,
  NavButton,
  Text,
  Row,
  ProfileButton,
  Section,
  Input,
  Column,
  Button
} from "./";
import Theme from "./Theme.js";
import { fadeInDown, rollIn } from "react-animations";
import Page_Background from "../assets/Page_Background.jpg";
import cookie from "js-cookie";
import windowSize from "react-window-size";
import moment from "moment";
const routes = [
  {
    label: "Home",
    route: "/home"
  }
];

class NavBar extends Component {
  render() {
    const mobile = this.props.windowWidth < 600;
    const tiny = this.props.windowWidth < 400;
    const menu = this.props.windowWidth < 1000;
    return (
      <div>
        {
          //   <div
          //     style={{
          //       display: "flex",
          //       height: "8vh",
          //       flexDirection: "row",
          //       width: "100vw",
          //       backgroundColor: Theme.WHITE,
          //       boxShadow: `0 0 10px ${Theme.BLACK}`,
          //       zIndex: 90,
          //       position: "fixed"
          //     }}
          //   >
          //     <img
          //       src={Circular}
          //       style={{
          //         height: "6vh",
          //         margin: "auto auto auto 1%"
          //       }}
          //     />
          //   </div>
        }
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "rgba(53,53,90,.0)",
            justifyContent: "flex-start",
            minHeight: "92vh"
          }}
        >
          {this.props.children}
        </div>
        <Section backgroundColor={Theme.GREEN}>
          <Row margin="2%" width="90vw">
            <Column margin="auto auto 0 0">
              <Text
                color="white"
                textAlign="left"
                size={mobile ? "2.5vw" : "1.5vw"}
                margin="0 auto 0 auto"
              >
                protestdrones.com@gmail.com
              </Text>
              <Text
                color="white"
                textAlign="left"
                size={mobile ? "2.5vw" : "1.5vw"}
                margin="10% auto 10% 0"
              >
                Micah Lasovsky
              </Text>
              <Text
                color="white"
                textAlign="left"
                margin="0 auto 0 0"
                size={mobile ? "2.5vw" : "1.5vw"}
              >
                &copy;{` ${moment().format("YYYY")} LawSum`}
              </Text>
            </Column>
            <Column margin="auto 0 0 auto">
              <Text
                size={mobile ? "3vw" : "1.5vw"}
                textAlign="left"
                color="white"
              >
                Sign up to be a pilot!
              </Text>
              <Input
                textSize={mobile ? "2vw" : "1vw"}
                placeholder="Your email"
                margin="5% auto 5% auto"
              />
              <Button
                background={Theme.WHITE}
                color={Theme.BLACK}
                fontSize={mobile ? "2.2vw" : "1.5vw"}
                width={mobile ? "41vw" : "20vw"}
              >
                Sign Up For Updates
              </Button>
            </Column>
          </Row>
        </Section>
      </div>
    );
  }
}

export default windowSize(NavBar);
