import styled, { keyframes, css } from "styled-components";
import { pulse, fadeInDown } from "react-animations";
import Theme from "./Theme.js";

export { default as NavBar } from "./navbar.js";
export { default as Dropdown } from "./dropdown.js";
export { default as Input } from "./input.js";
export { default as Notification } from "./notification.js";
export { default as Drawer } from "./drawer.js";
export { default as ProfileButton } from "./profileButton.js";
export { default as Table } from "./table.js";
export { default as Select } from "./select.js";

export const NavButton = styled.button`
  margin: ${props => props.margin || "auto 2vw auto 2vw"};
  outline: none;
  border: none;
  font-size: ${props => (props.mobile ? "1.5vh" : "2vh")};
  font-family: ${Theme.BANNER_FONT};
  font-weight: 800;
  background: none;
  color: ${props => props.color || "white"};
  width: ${props => props.width || "auto"};
  cursor: pointer;
  animation: ${props => props.animation};
  animation-iteration-count: 1;
  animation-delay: ${props => props.animationDelay};
  animation-fill-mode: both;
  text-align: ${props => props.align || "center"};
  position: ${props => props.position};
  border-radius: 20px;
  overflow: ${props => props.overflow || "visible"};
  &:hover {
    ${props =>
      props.disableHover
        ? null
        : css`
            animation: 2.5s ${keyframes`${pulse}`} infinite;
            animation-fill-mode: forwards;
            transition: background-color 1s ease;
            background-color: ${Theme.BLUE};
          `}
  }
`;

export const Clickable = styled.button`
  position: ${props => props.position};
  margin: ${props => props.margin || "auto 2vw auto 2vw"};
  outline: none;
  border: none;
  background: none;
  width: ${props => props.width || "auto"};
  cursor: pointer;
`;
export const Text = styled.div`
  position: ${props => props.position};
  width: ${props => props.width || "auto"};
  font-size: ${props => (props.size ? props.size : "2vh")};
  font-weight: ${props => (props.weight ? props.weight : "bold")};
  text-align: ${props => props.textAlign};
  display: ${props => (props.hidden ? "none" : "default")};
  margin-left: ${props => (props.align === "left" ? "0" : "auto")};
  margin-right: ${props => (props.align === "right" ? "0" : "auto")};
  margin: ${props => (props.margin ? props.margin : null)};
  color: ${props => (props.color ? props.color : "black")};
  animation: ${props => props.animation};
  animation-fill-mode: both;
  animation-delay: ${props => props.animationDelay};
`;

export const Button = styled.button`
  outline: none;
  border: none;
  font-size: ${props => props.fontSize || "auto"};
  font-weight: 800;
  border: ${props => props.border};
  border-radius: ${props => props.radius || "10px"};
  background-color: ${props => props.background || Theme.BUTTON_COLOR};
  color: ${props => props.color || Theme.BUTTON_TEXT_COLOR};
  cursor: pointer;
  margin: ${props => props.margin || "auto"};
  padding: ${props => props.padding || "1vh"};
  width: ${props => props.width || "20vw"};
  height: ${props => props.height || "auto"};
  animation: ${props => props.animation};
  animation-delay: ${props => props.animationDelay};
  animation-fill-mode: both;
  min-width: 100px;
  box-shadow: ${props => props.shadow || Theme.SHADOW_COLOR};
  &:hover {
    transition: font-size 1s ease, background 1s ease;
    font-size: ${props => props.fontSize + 0.5 || "auto"};
    background: ${props => props.hoverColor || Theme.SECONDARY_COLOR};
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: ${props => (props.mobile ? "column" : "row")};
  flex-wrap: ${props => props.noWrap || "wrap"};
  justify-content: ${props => props.justify};
  width: ${props => props.width || "auto"};
  height: ${props => props.height || "auto"};
  margin: ${props => props.margin || "auto"};
  border: ${props => props.border || "none"};
  box-shadow: ${props => props.boxShadow || "none"};
  background-color: ${props => props.backgroundColor || "none"};
  overflow: ${props => props.overflow || "visible"};
  overflow-x: ${props => props.overflowX || "visible"};
  overflow-y: ${props => props.overflowY || "visible"};
trasform:rotateX
  trasform: rotate;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: ${props => props.width || "auto"};
  height: ${props => props.height || "auto"};
  margin: ${props => props.margin || "auto"};
  border: ${props => props.border || "none"};
  box-shadow: ${props => props.boxShadow || "none"};
  background-color: ${props => props.backgroundColor || "none"};
  justifycontent: ${props => props.justifyContent};
`;

export const Section = styled.div`
  display: flex;
  position: ${props => props.position || "default"};
  flex-direction: column;
  margin: ${props => props.margin || "0"};
  padding: ${props => props.padding || "0"};
  height: ${props => props.height || "auto"};
  min-height: ${props => props.minHeight};
  width: ${props => props.width || "auto"};
  background-color: ${props => props.backgroundColor || "none"};
  border-bottom: ${props => props.border || "none"};
  animation: ${props => props.duration} ${props => props.animation};
  animation-delay: ${props => props.animationDelay};
  text-align: center;
  ${props =>
    props.transition === "out"
      ? css`
          transition: height 0.5s;
          height: 0px;
        `
      : css`
          transition: height 0.5s;
          height: props.height;
        `};
`;
export const Hint = styled.div`
  display: flex;
  margin: ${props => props.margin || "auto"};
  width: ${props => props.width || "auto"};
  font-weight: bold;
  justify-content: center;
  color: white;
  font-size: ${props => props.size || "2vw"};
  font-family: ${Theme.MAIN_FONT};
  background-color: black;
  border-radius: 20px;
  box-shadow: 0 0 10px #586387;
  animation: ${props => props.animation};
  animation-delay: ${props => props.animationDelay};
  animation-fill-mode: both;
`;

export const Image = styled.img`
  width: ${props => props.width || "auto"};
  height: ${props => props.height || "auto"};
  margin: ${props => props.margin || "auto"};
  border: ${props => props.border};
  background: none;
  position: ${props => props.position};
  display: ${props => props.display};
  left: ${props => props.left};
  right: ${props => props.right};
  top: ${props => props.top};
  bottom: ${props => props.bottom};
  border-radius: ${props => props.borderRadius};
  animation: ${props => props.animation};
  animation-fill-mode: both;
  animation-delay: ${props => props.animationDelay};
`;

export const DemoFrame = styled.div`
  display: flex;
  height: 60vh;
  border: ${props => (props.mobile ? "1vw solid black " : "0.4vw solid black")};
  box-shadow: 0 0 0.7vw black;
  border-radius: 0.4vw;
  margin: ${props =>
    props.mobile ? "6vh auto auto auto" : "6vh auto auto 1vw"};
`;

export const InfoBox = styled.div`
  background-color: ${props => props.color || "white"};
  width: ${props => props.width || "auto"};
  height: ${props => props.height || "auto"};
  min-height: ${props => props.minHeight};
  display: flex;
  margin: ${props => props.margin || "auto"};
  padding: ${props => props.padding || "1%"};
  border-radius: 0.5vw;
  box-shadow: ${props => (props.shadow ? "0 0 1vw black" : "none")};
  border: 1px solid black;
  flex-direction: column;
`;

export const InfoCircle = styled.div`
  background-color: white;
  margin: ${props => props.margin || "auto"};
  display: flex;
  box-shadow: 0 0 1vw black;
  width: ${props => props.width};
  height: ${props => props.width};
  border-radius: 100%;
`;

export const Divider = styled.div`
  height: ${props => props.height || "1vw"};
  width: ${props => props.width || "100%"};
  background-color: ${props => props.color || "black"};
  margin: ${props => props.margin || "auto"};
  border-radius: ${props => props.radius || "10px"};
`;
