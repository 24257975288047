import React from "react";
import { Home, Login, Settings } from "./pages";
import cookie from "js-cookie";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from "react-router-dom";

function App() {
  const token = cookie.get("token");
  return <Home />;
}

export default App;
