import React from "react";
import cookie from "js-cookie";
import axios from "axios";
import windowSize from "react-window-size";
import ReactTwitchEmbedVideo from "react-twitch-embed-video";
import {
  NavBar,
  Section,
  InfoBox,
  Text,
  Row,
  Input,
  Divider
} from "../../globalComponents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExpandArrowsAlt,
  faWindowClose
} from "@fortawesome/free-solid-svg-icons";
import Theme from "../../globalComponents/Theme.js";
import Logo from "../../assets/logos/Main_Logo.png";
import Lawsum from "../../assets/logos/lawsum.png";
import Nitely from "../../assets/logos/nitely.png";
import ReactPlayer from "react-player";
import { channels } from "../../utils/channels.js";

class Home extends React.Component {
  state = {
    fullscreen: false,
    videos: [],
    region: {
      id: "UCW0DrliWtQy4jOdtKdgrhdQ",
      type: "youtube",
      url:
        "https://www.youtube.com/embed/live_stream?channel=UCW0DrliWtQy4jOdtKdgrhdQ"
    }
  };
  getVideos = () => {
    this.state.region.type === "youtube"
      ? axios
          .get(`https://www.googleapis.com/youtube/v3/search`, {
            params: {
              part: "snippet",
              key: process.env.REACT_APP_GOOGLE_KEY,
              channelId: this.state.region.id,
              eventType: "completed",
              type: "video"
            }
          })
          .then(res => {
            let temp = res.data.items;
            let videos = [];
            temp.map(video => {
              if (video.id.kind === "youtube#video")
                videos.push({
                  title: video.snippet.title,
                  id: video.id.videoId
                });
            });
            this.setState({ videos: videos });
          })
      : axios
          .post(
            `https://id.twitch.tv/oauth2/token?client_id=${process.env.REACT_APP_TWITCH_CLIENT_ID}&client_secret=${process.env.REACT_APP_TWITCH_SECRET}&grant_type=client_credentials`
          )
          .then(res => {
            axios
              .get("https://api.twitch.tv/helix/videos", {
                headers: {
                  Authorization: `Bearer ${res.data.access_token}`,
                  "Client-ID": process.env.REACT_APP_TWITCH_CLIENT_ID
                },
                params: {
                  user_id: this.state.region.id
                }
              })
              .then(res => this.setState({ videos: res.data.data }));
          });
  };
  componentDidMount() {
    this.getVideos();
  }
  render() {
    const { fullscreen, videos, region } = this.state;
    const height = this.props.windowHeight;
    const width = this.props.windowWidth;
    const mobile = this.props.windowWidth < 800;
    return (
      <NavBar history={this.props.history}>
        <Section backgroundColor={Theme.GREEN} padding="0 0 2% 0">
          <img
            src={Logo}
            style={{
              margin: "5px auto 5px auto",
              height: "auto",
              width: mobile ? "25vw" : "15vw"
            }}
          />
          <Text size={mobile ? "4vw" : "2.5vw"}>
            Providing coverage of protests around the country.
          </Text>
          <Text margin="2% auto auto auto"> Select A LiveStream Region</Text>
          <Input
            width={mobile ? "90vw" : "60vw"}
            options={channels}
            onChange={v => this.setState({ region: v }, this.getVideos)}
          />
        </Section>
        <Section backgroundColor="white" padding="1% 0 1% 0">
          {region.type === "youtube" ? (
            <iframe
              key={region}
              style={{
                margin: "auto",
                width: mobile ? "95vw" : "80vw",
                height: mobile ? "50vw" : "40vw"
              }}
              src={region.url + "&autoplay=1"}
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen="true"
            />
          ) : (
            <div style={{ display: "flex", margin: "auto" }}>
              <ReactTwitchEmbedVideo
                width={mobile ? width * 0.95 : width * 0.8}
                height={mobile ? width * 0.5 : width * 0.4}
                targetClass="main"
                layout="video"
                channel={region.url}
              />
            </div>
          )}
          <Text size={mobile ? "4vw" : "2.5vw"} margin="3% auto 2% auto">
            Brought to you by
          </Text>
          <Row justify="space-between">
            <a
              style={{
                border: "none",
                outline: "none",
                cursor: "pointer",
                display: "flex",
                highlight: "none",
                textDecoration: "none"
              }}
              href="https://lawsum.org"
              target="_blank"
            >
              <img
                src={Lawsum}
                style={{
                  objectFit: "contain",
                  height: "100%",
                  width: "30vw",
                  margin: "0 5vw 0 auto"
                }}
              />
            </a>
            <a
              style={{
                border: "none",
                outline: "none",
                cursor: "pointer",
                display: "flex",
                highlight: "none",
                textDecoration: "none"
              }}
              href="https://nitely.dev"
              target="_blank"
            >
              <img
                src={Nitely}
                style={{
                  margin: "0 auto 0 auto",

                  height: "20vw",
                  width: "auto"
                }}
              />
            </a>
          </Row>
        </Section>
        <Section backgroundColor={"black"} padding="2% 0 2% 0" minHeight="80vh">
          <Text size={mobile ? "6vw" : "4vw"} color={Theme.WHITE}>
            Past Protests
          </Text>
          <Divider
            width={mobile ? "55vw" : "30vw"}
            margin="1% auto 2% auto"
            color={Theme.GREEN}
          />
          {videos.map((entry, index) => (
            <InfoBox
              margin={fullscreen === index ? "auto" : "2% auto 2% auto"}
              padding={"0 2% 2% 2%"}
              width={fullscreen === index ? "98vw" : mobile ? "92vw" : "80vw"}
            >
              <Text size={mobile ? "4vw" : "2vw"} margin="0 0 1% 0">
                {entry.title}
              </Text>

              <div
                style={{
                  display: "flex",
                  margin: "auto"
                }}
              >
                {region.type === "youtube" ? (
                  <iframe
                    style={{
                      width: mobile ? "90vw" : "80vw",
                      height: mobile ? "50vw" : "40vw"
                    }}
                    src={`https://www.youtube.com/embed/${entry.id}`}
                    frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen="true"
                  />
                ) : (
                  <ReactTwitchEmbedVideo
                    video={entry.id}
                    autoplay={false}
                    targetId={entry.title}
                    layout="video"
                    width={fullscreen === index ? width : width / 2}
                    height={fullscreen === index ? width / 2 - 100 : width / 4}
                    allowfullscreen={false}
                  />
                )}
              </div>
            </InfoBox>
          ))}
        </Section>
        <a
          style={{
            border: "none",
            outline: "none",
            cursor: "pointer",
            display: "flex",
            position: "fixed",
            bottom: 0,
            right: 10,
            padding: 5,
            backgroundColor: Theme.BLACK,
            borderRadius: "10px 10px 0 0",
            minWidth: 100,
            highlight: "none",
            textDecoration: "none"
          }}
          href="https://nitely.dev"
          target="_blank"
        >
          <Text color="white">Powered By Nitely</Text>
        </a>
      </NavBar>
    );
  }
}

export default windowSize(Home);
