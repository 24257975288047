import React from "react";
import cookie from "js-cookie";
import axios from "axios";
import windowSize from "react-window-size";
import {
  NavBar,
  Section,
  InfoBox,
  Text,
  Input,
  Button
} from "../../globalComponents";
import Theme from "../../globalComponents/Theme.js";

class Forms extends React.Component {
  state = {
    email: "",
    first: "",
    last: "",
    error: { name: "", text: "" },
    id: ""
  };
  getInfo() {
    axios
      .get("/user/current")
      .then(res => {
        if (res.data.success) {
          const user = res.data.user;
          this.setState({
            error: { name: "", text: "" },
            email: user.email,
            first: user.firstName,
            last: user.lastName,
            id: user._id
          });
        } else
          this.setState({
            error: { name: "general", text: res.data.error }
          });
      })
      .catch(err => {
        this.setState({
          error: { name: "general", text: "Server Error Try Again" }
        });
      });
  }
  settings() {
    axios
      .post("/user/settings", {
        email: this.state.email,
        firstName: this.state.first,
        lastName: this.state.last,
        id: this.state.id
      })
      .then(res => {
        if (res.data.success) {
          cookie.set(
            "name",
            res.data.user.firstName + " " + res.data.user.lastName
          );
          this.setState({ error: { name: "", text: "" } });
        } else
          this.setState({
            error: { name: "general", text: res.data.error }
          });
      })
      .catch(err => {
        console.error(err);
        this.setState({
          error: { name: "general", text: "Server Error Try Again" }
        });
      });
  }
  componentDidMount() {
    this.getInfo();
  }
  render() {
    const { email, first, last, password, error } = this.state;
    return (
      <NavBar history={this.props.history}>
        <Section backgroundColor={"rgba(0,0,0,.3)"} height="92vh">
          <InfoBox margin="2% auto auto auto">
            <Text size="4vh" margin="2%">
              Settings
            </Text>
            <Input
              label="Email"
              value={email}
              onChange={v => this.setState({ email: v })}
            />
            <Input
              label="First Name"
              value={first}
              onChange={v => this.setState({ first: v })}
            />
            <Input
              label="Last Name"
              value={last}
              onChange={v => this.setState({ last: v })}
            />
            <Text color="red">{error.name == "general" && error.text}</Text>
            <Button margin="1vh auto auto auto" onClick={() => this.settings()}>
              Submit
            </Button>
          </InfoBox>
        </Section>
      </NavBar>
    );
  }
}

export default windowSize(Forms);
